// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check";

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

import vars from './_vars';

export const disableScroll = () => {
  const fixBlocks = document?.querySelectorAll('.fixed-block');
  const pagePosition = window.scrollY;
  const paddingOffset = `${(window.innerWidth - vars.bodyEl.offsetWidth)}px`;

  vars.htmlEl.style.scrollBehavior = 'none';
  fixBlocks.forEach(el => { el.style.paddingRight = paddingOffset; });
  vars.bodyEl.style.paddingRight = paddingOffset;
  vars.bodyEl.classList.add('dis-scroll');
  vars.bodyEl.dataset.position = pagePosition;
  vars.bodyEl.style.top = `-${pagePosition}px`;
}

export const enableScroll = () => {
  const fixBlocks = document?.querySelectorAll('.fixed-block');
  const body = document.body;
  const pagePosition = parseInt(vars.bodyEl.dataset.position, 10);
  fixBlocks.forEach(el => { el.style.paddingRight = '0px'; });
  vars.bodyEl.style.paddingRight = '0px';

  vars.bodyEl.style.top = 'auto';
  vars.bodyEl.classList.remove('dis-scroll');
  window.scroll({
    top: pagePosition,
    left: 0
  });
  vars.bodyEl.removeAttribute('data-position');
  vars.htmlEl.style.scrollBehavior = 'smooth';
}
const mobileHeader = document.querySelector('.mobile__header');

(function () {
  const burger = document?.querySelector('[data-burger]');
  const menu = document?.querySelector('[data-menu]');
  const menuItems = document?.querySelectorAll('[data-menu-item]');
  const overlay = document?.querySelector('[data-menu-overlay]');


  burger?.addEventListener('click', (e) => {
    if (mobileHeader.classList.contains('select-sity-menu__active')) {
      selectSityMenu.classList.remove('select-sity-menu__active');
      mobileHeader.classList.remove('select-sity-menu__active');
      headerMenu.classList.remove('select-sity-menu__active')
    }
    else {
      burger?.classList.toggle('burger--active');
      menu?.classList.toggle('menu--active');
      mobileHeader.classList.toggle('header--active');
      getHeaderHeight();

      if (menu?.classList.contains('menu--active')) {
        burger?.setAttribute('aria-expanded', 'true');
        burger?.setAttribute('aria-label', 'Закрыть меню');
        disableScroll();
      } else {
        burger?.setAttribute('aria-expanded', 'false');
        burger?.setAttribute('aria-label', 'Открыть меню');
        enableScroll();
      }
    }
  });

  overlay?.addEventListener('click', () => {
    burger?.setAttribute('aria-expanded', 'false');
    burger?.setAttribute('aria-label', 'Открыть меню');
    burger.classList.remove('burger--active');
    menu.classList.remove('menu--active');
    enableScroll();
  });

  menuItems?.forEach(el => {
    el.addEventListener('click', () => {
      burger?.setAttribute('aria-expanded', 'false');
      burger?.setAttribute('aria-label', 'Открыть меню');
      burger.classList.remove('burger--active');
      menu.classList.remove('menu--active');
      enableScroll();
    });
  });
})();


// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();


const allSwitchBoxes = document.querySelectorAll('.switchBox');

allSwitchBoxes.forEach(switchBox => {
  const switchBoxItems = switchBox.querySelectorAll('.switchBox-item')
  for (let index = 0; index < switchBoxItems.length; index++) {
    switchBoxItems[index].addEventListener('click', () => {
      switchBox.style.setProperty('--tab-active-translate', "calc(" + index * 100 + '% + ' + index * 10 + 'px' + ")");
      console.log('Активный индекс switchBox = ' + index)
    })
  }
})

function getActiveIndexInTab(tab) {
  const tabsEl = document.querySelector('[data-tabs="' + tab.selector + '"]')
  const tabPanels = tabsEl.querySelectorAll('.tabs__nav-btn');
  for (let index = 0; index < tabPanels.length; index++) {
    if (tabPanels[index].classList.contains('tabs__nav-btn--active')) {
      return index;
    }
  }
}


function getHeaderHeight() {
  const headerHeight = document?.querySelector('.mobile__header').offsetHeight;
  document.querySelector(':root').style.setProperty('--header-height', `${headerHeight}px`);
}

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, FreeMode } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);

const popupLinks = document.querySelectorAll(".popup__link");
const body = document.querySelector("body");
const lockPadding = document.querySelectorAll(".lock__padding");


let unlock = true;
let storyesPopup;
const timeout = 200;

if (popupLinks.length > 0) {
  for (let index = 0; index < popupLinks.length; index++) {
    const popupLink = popupLinks[index];
    popupLink.addEventListener("click", function (e) {
      const popupName = popupLink.getAttribute("data-popupId");
      const curentPopup = document.getElementById(popupName);
      if (curentPopup.classList.contains('storyes')) {
        storyesPopup = curentPopup;
        popupLink.classList.add('viewed')
      }
      popupOpen(curentPopup);
      e.preventDefault();
    });
  }
}
const popupCloseIcon = document.querySelectorAll(".close__popup");
if (popupCloseIcon.length > 0) {
  for (let index = 0; index < popupCloseIcon.length; index++) {
    const el = popupCloseIcon[index];
    el.addEventListener("click", function (e) {
      popupClose(el.closest(".popup"));
      e.preventDefault();
    });
  }
}

let storyPopupLinks = [];

popupLinks.forEach((link, index) => {
  if (link.classList.contains('storyboard__slide')) {
    storyPopupLinks.push(link);
  }
})

let popupStoryesOpen = false;

for (let index = 0; index < storyPopupLinks.length; index++) {
  storyPopupLinks[index].addEventListener('click', () => {
    popupStoryesOpen = true;
    initObjectSwipers();
    swiperStory.init();
    swiperStoryFragments.forEach(swiper => {
      swiper.init();
    })
    swiperStory.slideTo(index, 1);
    setDelay()
  })
}



function popupOpen(curentPopup) {
  if (curentPopup && unlock) {
    const popupActive = document.querySelector(".popup.open");
    if (popupActive) {
      if (!popupActive.classList.contains('popup-some-products')) {
        popupClose(popupActive, false);
      }
    } else {
      bodyLock();
    }
    curentPopup.classList.add("open");
    curentPopup.addEventListener("click", function (e) {
      if (!e.target.closest(".popup__content")) {
        popupClose(e.target.closest(".popup"));
      }
    });
  }
}
function popupClose(popupActive, doUnlock = true) {
  if (unlock) {
    if (popupActive?.classList.contains('storyes')) {
      mutedVideo(swiperStory);
      stopAllAutoplay();
    }
    popupActive?.classList.remove("open");
    if (doUnlock) {
      bodyUnLock();
    }
  }
}

function mutedVideo(swiper) {
  if (swiper.el !== undefined) {
    const allVideos = swiper.el.querySelectorAll('video')
    allVideos.forEach(video => {
      video.muted = true;
      video.pause();
    })
  } else {
    const allVideos = swiper.querySelectorAll('video')
    allVideos.forEach(video => {
      video.muted = true;
      video.pause();
    })
  }
}

function stopAllAutoplay() {
  swiperStoryFragments.forEach(swiper => {
    swiper.destroy()
  })
  swiperStory.destroy();
}

function bodyLock() {
  const lockPaddingValue =
    window.innerWidth - document.querySelector("body").offsetWidth + "px";

  if (lockPadding.length > 0) {
    for (let index = 0; index < lockPadding.length; index++) {
      const el = lockPadding[index];
      el.style.paddingRight = lockPaddingValue;
    }
  }
  body.style.paddingRight = lockPaddingValue;
  body.classList.add("lock");

  unlock = false;
  setTimeout(function () {
    unlock = true;
  }, timeout);
}

function bodyUnLock() {
  setTimeout(() => {
    for (let index = 0; index < lockPadding.length; index++) {
      const el = lockPadding[index];
      el.style.paddingRight = "0px";
    }
    body.style.paddingRight = "0px";
    body.classList.remove("lock");
  }, timeout);

  unlock = false;
  setTimeout(function () {
    unlock = true;
  }, timeout);
}

document.addEventListener("keydown", function (e) {
  if (e.which === 27) {
    const popupActive = document.querySelector(".popup.open");
    popupClose(popupActive);
  }
});

(function () {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      let node = this;
      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
  }
})();
(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      Element.prototype.msMatchesSelector;
  }
})();


const swiperStoryboardEl = document.querySelector('.swiper__storyboard');
const delayDefault = 3000;

function resetDelay() {
  swiperStory.slides.forEach(slide => {
    const swiperFragmentEl = slide.querySelector('.swiper__story-slide')
    const swiperFragment = swiperFragmentEl.swiper
    let swiperStorySlideDelay = 0;
    for (let index = swiperFragment.activeIndex; index < swiperFragment.slides.length; index++) {
      const videoEl = swiperFragment.slides[index].querySelector('video')
      if (videoEl !== null) {
        swiperStorySlideDelay += videoEl.duration * 1000;
      }
      else {
        swiperStorySlideDelay += delayDefault;
      }
    }
    slide.setAttribute('data-swiper-autoplay', swiperStorySlideDelay)
  })
  swiperStory.autoplay.start();
}


// Расчитывается задержка для КАЖДОГО слайда и устанавливаться в атрибут
// swiper потом сам их читает и устанавливает delay на КАЖДЫЙ слайд
function setDelay() {
  swiperStory.slides.forEach(slide => {
    const swiperFragmentEl = slide.querySelector('.swiper__story-slide')
    const swiperFragment = swiperFragmentEl.swiper
    let swiperStorySlideDelay = 0;
    swiperFragment.slides.forEach(slideFragment => {
      const videoEl = slideFragment.querySelector('video')
      if (videoEl !== null) {
        slideFragment.setAttribute('data-swiper-autoplay', videoEl.duration * 1000)
        swiperStorySlideDelay += videoEl.duration * 1000;
      }
      else {
        slideFragment.setAttribute('data-swiper-autoplay', delayDefault)
        swiperStorySlideDelay += delayDefault;
      }
    })
    slide.setAttribute('data-swiper-autoplay', swiperStorySlideDelay)
  })

  const curentSlide = swiperStory.slides[swiperStory.activeIndex]
  const swiperFragmentEl = curentSlide.querySelector('.swiper__story-slide')
  const swiperFragment = swiperFragmentEl.swiper
  swiperFragment.slideTo(0)
  swiperFragment.enable();
  setBgImg(swiperFragment)
  swiperStory.autoplay.start();
  swiperFragment.autoplay.start();
  playVideo(swiperFragment.slides[0]);
}

function playVideo(slide) {
  const video = slide.querySelector('video')
  if (video !== null) {
    video.muted = false;
    video.currentTime = 0;
    video.play();
  }
}


let swiperStory;
let swiperStoryFragments;

function initObjectSwipers() {
  swiperStory = new Swiper(".swiper__story", {
    // allowTouchMove: false,
    spaceBetween: 5,
    init: false,
    navigation: {
      nextEl: ".story-btn.next",
      prevEl: ".story-btn.prev",
    },
    autoplay: {
      delay: delayDefault,
      stopOnLastSlide: true,
      disableOnInteraction: false,
    },
    on: {
      init(swiper) {
        swiper.autoplay.stop();
      },
      slideChange(swiper) {
        enableSwiperSlide(swiper)
        setBgImg(swiper.slides[swiper.activeIndex].querySelector('.swiper').swiper)
      }
    }
  });


  swiperStoryFragments = new Swiper(".swiper__story-slide", {
    allowTouchMove: false,
    speed: 1,
    init: false,
    autoplay: {
      delay: delayDefault,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.pagination-fragments',
    },

    on: {
      init(swiper) {
        swiper.autoplay.stop();
        swiper.disable();
      },
      slideChange(swiper) {
        mutedVideo(swiper.slides[swiper.previousIndex])
        playVideo(swiper.slides[swiper.activeIndex])
        setStyleForBullets(swiper);
        resetDelay();
        setBgImg(swiper)
        if (!swiper.isEnd) {
          clearTimeout(timerId)
        }
      },
      toEdge(swiper) {
        if (swiper.isEnd && swiperStory.isEnd) {
          const slideDelay = swiper.slides[swiper.activeIndex].getAttribute('data-swiper-autoplay')
          timerId = setTimeout(() => {
            popupClose(storyesPopup)
          }, slideDelay - 10)
        }
      },
      autoplayTimeLeft(swiper, time, progress) {
        body.style.setProperty('--width-active-bullet', (1 - progress) * 100 + "%")
      }
    }
  }
  );
}

let timerId;

function setBgImg(swiper) {
  const activeSlide = swiper.slides[swiper.activeIndex]
  const imgInSlide = activeSlide.querySelector('img')
  if (imgInSlide !== null) {
    storyesPopup.style.background = 'url(' + imgInSlide.src + ') no-repeat center center / cover'
  }
  else {
    const imgBg = storyPopupLinks[swiperStory.activeIndex].querySelector('img')
    storyesPopup.style.background = 'url(' + imgBg.src + ') no-repeat center center / cover'
  }
}


function setStyleForBullets(swiper) {
  // Получаем все элементы с классом 'swiper-pagination-bullet'
  const bullets = swiper.pagination.bullets

  let activeFound = false;
  bullets.forEach(bullet => {
    if (!activeFound) {
      if (!bullet.classList.contains('swiper-pagination-bullet-active')) {
        // Применяем стиль ко всем элементам до активного
        bullet.style.setProperty('background-color', '#fff', 'important');
      } else {
        // Если текущий элемент активный, снимаем с него стиль
        bullet.style.removeProperty('background-color');
        activeFound = true;
      }
    } else {
      // Снимаем стиль с элементов после активного
      bullet.style.removeProperty('background-color');
    }
  });
}


const swiperStoryboard = new Swiper(swiperStoryboardEl, {
  slidesPerView: 'auto',
  spaceBetween: 10,
  freeMode: true,
  navigation: {
    nextEl: ".storyboard-btn.next",
    prevEl: ".storyboard-btn.prev",
  }
});


// Используется чтобы запустить автоплей на активном слайде
// и остановить на предыдущем
function enableSwiperSlide(swiper) {
  const prevSlide = swiper.slides[swiper.previousIndex]
  const swiperOnPrevSlide = prevSlide.querySelector('.swiper').swiper
  mutedVideo(swiperOnPrevSlide)
  swiperOnPrevSlide.autoplay.stop();
  swiperOnPrevSlide.disable();

  const activeSlide = swiper.slides[swiper.activeIndex]
  const swiperOnActive = activeSlide.querySelector('.swiper').swiper
  swiperOnActive.enable();
  if (swiper.previousIndex > swiper.activeIndex) {
    swiperOnActive.slideTo(swiperOnActive.slides.length - 1)
    playVideo(swiperOnActive.slides[swiperOnActive.activeIndex])
  } else {
    swiperOnActive.slideTo(0)
    playVideo(swiperOnActive.slides[swiperOnActive.activeIndex])
  }
  swiperOnActive.autoplay.start();
}


function changeSlideOnSwipe(e) {
  if (e.detail.dir == "left") {
    swiperStory.slideNext()
  }
  else if (e.detail.dir == "right") {
    swiperStory.slidePrev()
  }
}

// Подключение событий свайпа на мобильных
import 'swiped-events';


const storyFragmentBtnNext = document.querySelector('.story-fragment-btn.next');
const storyFragmentBtnPrev = document.querySelector('.story-fragment-btn.prev');


storyFragmentBtnNext?.addEventListener('swiped', function (e) {
  changeSlideOnSwipe(e)
});

storyFragmentBtnPrev?.addEventListener('swiped', function (e) {
  changeSlideOnSwipe(e)
});


storyFragmentBtnNext?.addEventListener('click', () => {
  const curentSlide = swiperStory.slides[swiperStory.activeIndex]
  const swiperFragmentEl = curentSlide.querySelector('.swiper__story-slide')
  const swiperFragment = swiperFragmentEl.swiper
  if (swiperFragment.isEnd) {
    if (swiperStory.isEnd) {
      popupClose(storyesPopup);
    }
    swiperStory.slideNext();
  }
  else {
    swiperFragment.slideNext();
  }
})
storyFragmentBtnPrev?.addEventListener('click', () => {
  const curentSlide = swiperStory.slides[swiperStory.activeIndex]
  const swiperFragmentEl = curentSlide.querySelector('.swiper__story-slide')
  const swiperFragment = swiperFragmentEl.swiper
  if (swiperFragment.isBeginning) {
    swiperStory.slidePrev();
  }
  else {
    swiperFragment.slidePrev();
  }
})

const swiperOften = new Swiper(".swiper__often-order", {
  slidesPerView: 'auto',
  spaceBetween: 25,
  navigation: {
    nextEl: ".often-btn.next",
    prevEl: ".often-btn.prev",
  }
})




function updateStars(rating) {
  const stars = document.querySelectorAll('.star-rating .star');

  stars.forEach(star => {
    const value = parseFloat(star.getAttribute('data-value'));
    let fillPercentage = 0;

    if (value <= rating) {
      fillPercentage = 100;
    } else if (value - 1 < rating) {
      fillPercentage = (rating - (value - 1)) * 100;
    }

    star.style.background = `linear-gradient(to right, #fc0 0%, #fc0 ${fillPercentage}%, #ccc ${fillPercentage}%, #ccc 100%)`;
  });
}

const rateValue = document.querySelector('.delivery-time__rate');
updateStars(rateValue.textContent);





// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
import SmoothScroll from 'smooth-scroll';


const navMenu = document.querySelector('.nav-menu-line')

navMenu?.querySelectorAll('.nav-list__link').forEach(link => {
  const scroll = new SmoothScroll('a[href*="' + link.getAttribute('href') + '"]', {
    header: '[data-scroll-header]',
  });
})

window.onscroll = function () {
  makeHeaderSticky();
};

const header = document.querySelector('.header-fixed')
const placeholder = document.querySelector(".header-placeholder");
const sticky = header?.offsetTop

function makeHeaderSticky() {
  if (window.scrollY > sticky) {
    placeholder.style.height = header.offsetHeight + "px";
    header.classList.add("fixed");
  } else {
    if (placeholder !== null) {
      placeholder.style.height = "0px";
      header.classList.remove("fixed");
    }
  }
}



// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);

function setColumns() {
  const list = document.querySelector(".popup-sityes-list");
  if (list !== null) {
    const groups = Array.from(list.querySelectorAll('.sityes-group')); // Все группы, включая скрытые
    const visibleGroups = groups.filter(group => !group.classList.contains('hide-group')); // Только видимые группы
    const computedStyle = window.getComputedStyle(list);
    const columns = computedStyle.gridTemplateColumns.split(" ").length; // Количество колонок

    // Получаем все контейнеры колонок
    let columnContainers = Array.from(list.querySelectorAll('.column_sityes'));

    // Если количество колонок меньше, чем ожидается, показываем ошибку или добавляем колонки
    if (columnContainers.length < columns) {
      // console.error("Количество контейнеров колонок меньше, чем колонок в грид-сетке.");
      return; // Прекращаем выполнение, чтобы избежать ошибки
    }

    // Очищаем существующие колонки
    columnContainers.forEach(column => column.innerHTML = '');

    // Сортируем видимые группы по алфавиту
    visibleGroups.sort((a, b) => {
      const textA = a.querySelector('.letter__sityes').textContent.trim();
      const textB = b.querySelector('.letter__sityes').textContent.trim();
      return textA.localeCompare(textB);
    });

    // Подсчитываем общее количество видимых .sity-name во всех видимых группах
    const totalItems = visibleGroups.reduce((total, group) => {
      const sityNames = group.querySelectorAll('.sity-name:not(.hide)');
      return total + sityNames.length;
    }, 0);

    const itemsPerColumn = Math.ceil(totalItems / columns); // Количество .sity-name на каждый столбец

    let currentColumnIndex = 0;
    let currentItemCount = 0;

    // Распределяем видимые группы по колонкам
    visibleGroups.forEach(group => {
      const visibleSityNames = group.querySelectorAll('.sity-name:not(.hide)').length;

      // Проверяем, не вышли ли за пределы массива колонок
      if (currentColumnIndex >= columnContainers.length) {
        // console.error("Индекс текущей колонки превышает количество доступных колонок.");
        return;
      }

      // Если добавление группы превышает лимит текущего столбца, переходим к следующему
      if (currentItemCount + visibleSityNames > itemsPerColumn && currentColumnIndex < columns - 1) {
        currentColumnIndex++;
        currentItemCount = 0;
      }

      // Добавляем видимую группу в текущий столбец
      columnContainers[currentColumnIndex].appendChild(group);

      currentItemCount += visibleSityNames;
    });

    // Возвращаем скрытые группы обратно в DOM после распределения видимых групп
    groups.forEach(group => {
      if (group.classList.contains('hide-group')) {
        list.appendChild(group); // Возвращаем скрытые группы в исходное положение в DOM
      }
    });
  }
}

setColumns();

const parentContainer = document.querySelector('.sityes-list-container')
const container = document.querySelector('.popup-sityes-list');

function updateShadows() {
  const scrollTop = container.scrollTop;
  const scrollHeight = container.scrollHeight;
  const clientHeight = container.clientHeight;

  // Обновляем прозрачность верхней тени
  const topOpacity = scrollTop > 0 ? '1' : '0';
  parentContainer.style.setProperty('--top-shadow-opacity', topOpacity);

  // Обновляем прозрачность нижней тени
  const bottomOpacity = (scrollTop + clientHeight + 10 < scrollHeight) ? '1' : '0';
  parentContainer.style.setProperty('--bottom-shadow-opacity', bottomOpacity);
}

// Обновляем тени при скролле
container?.addEventListener('scroll', updateShadows);


const parentContainerMobile = document.querySelector('.mobile__sityes-list-container')
const containerMobile = document.querySelector('.mobile__popup-sityes-list');

function updateShadowsMobile() {
  const scrollTop = containerMobile.scrollTop;
  const scrollHeight = containerMobile.scrollHeight;
  const clientHeight = containerMobile.clientHeight;

  // Обновляем прозрачность верхней тени
  const topOpacity = scrollTop > 0 ? '1' : '0';
  parentContainerMobile.style.setProperty('--top-shadow-opacity', topOpacity);

  // Обновляем прозрачность нижней тени
  const bottomOpacity = (scrollTop + clientHeight < scrollHeight) ? '1' : '0';
  parentContainerMobile.style.setProperty('--bottom-shadow-opacity', bottomOpacity);
}

// Обновляем тени при скролле
containerMobile.addEventListener('scroll', updateShadowsMobile);



// Обновляем тени при загрузке страницы
updateShadowsMobile();



const mediaQuery = window.matchMedia('(max-width: 978px)');
const popupSelectSity = document.querySelector('.popup_select-sity')
// Функция для обработки изменения медиа-запроса
function handleMediaChange(e) {
  if (e.matches) {
    popupClose(popupSelectSity);
  }
}

// Проверяем текущий статус медиа-запроса
handleMediaChange(mediaQuery);

// Добавляем слушатель для изменения состояния медиа-запроса
mediaQuery.addEventListener('change', handleMediaChange);


const editSelectSityBtn = document.querySelector('.mobile__delivery')
const selectSityMenu = document.querySelector('.mobile__select-sity-menu')

editSelectSityBtn.addEventListener('click', () => {
  selectSityMenu.classList.add('select-sity-menu__active');
  body.classList.add('lock')
})

const btnCloseSelect = document.querySelector('.btn-select-close')

btnCloseSelect.addEventListener('click', () => {
  selectSityMenu.classList.remove('select-sity-menu__active');
  body.classList.remove('lock')
})


import tippy from 'tippy.js';


const tippyBtns = document.querySelectorAll('.tippy-btn')


tippyBtns.forEach(tippyBtn => {
  const tippyContent = tippyBtn.querySelector('.tippy__info');
  tippy(tippyBtn, {
    content: tippyContent,
    trigger: 'click',
    placement: "bottom-end",
    interactive: true,
  })
})

const tippyBtnLight = document.querySelector('.tippy-btn__light')
const tippyBtnLightContent = tippyBtnLight.querySelector('.tippy__info__mobile');


tippy(tippyBtnLight, {
  theme: 'light',
  content: tippyBtnLightContent,
  trigger: 'click',
  placement: "bottom-end",
  interactive: true,
})


const decreaseBtn = document.querySelector('.decrease');
const increaseBtn = document.querySelector('.increase');
const inputField = document.querySelector('.numeric-input');

decreaseBtn?.addEventListener('click', function () {
  let currentValue = parseInt(inputField.value);
  if (currentValue > 0) {
    inputField.value = currentValue - 1;
  }
});

increaseBtn?.addEventListener('click', function () {
  let currentValue = parseInt(inputField.value);
  inputField.value = currentValue + 1;
});

const productLists = document.querySelectorAll('.product-items')

const defaultImg = document.querySelector('.popup-some-products__img')
const productListMenu = document.querySelector('.products-list');
const accessoryMenu = document.querySelector('.accessory-menu')

productLists.forEach(list => {
  const productItems = list.querySelectorAll('.product-item')
  productItems.forEach(item => {
    const btnEdit = item.querySelector('.btn-edit')
    btnEdit.addEventListener('click', () => {
      item.classList.toggle('editable')
      if (!item.classList.contains('selected')) {
        item.classList.add('selected')
        productItems.forEach(otherItem => {
          if (otherItem != item) {
            otherItem.classList.toggle('disabled');
          }
        })
      }
      if (!defaultImg.classList.contains('hide')) {
        defaultImg.classList.add('hide')
      }
      if (productListMenu.classList.contains('show')) {
        productListMenu.classList.remove('show')
      }
      accessoryMenu.classList.toggle('show');

    })
    item.addEventListener('click', (e) => {
      const isBtn = !e.target.classList.contains('btn-edit')
      const isSwitch = !e.target.classList.contains('switchBox-item')

      if (isBtn && isSwitch) {
        if (item.classList.contains('editable')) {
          item.classList.remove('editable')
        }
        if (!item.classList.contains('selected')) {
          item.classList.add('selected')
          productListMenu.classList.add('show');
          defaultImg.classList.add('hide')
          productItems.forEach(otherItem => {
            if (otherItem != item) {
              otherItem.classList.add('disabled');
            }
          })
        }
        else {
          item.classList.remove('selected')
          productListMenu.classList.remove('show')
          accessoryMenu.classList.remove('show');
          defaultImg.classList.remove('hide')
          productItems.forEach(otherItem => {
            if (otherItem != item) {
              otherItem.classList.remove('disabled');
            }
          })
        }
      }
    })
  })
})


const btnCoockie = document.querySelector('.btn-coockie-close')
const coockieBanner = document.querySelector('.cookie__bunner');

btnCoockie?.addEventListener('click', () => {
  coockieBanner.style.display = "none"
})


const inputSearchSity = document.querySelector('.select-sity_input')
const sityList = document.querySelectorAll('.sityes-group')
const sityNotFound = document.querySelector('.siteys-not-found-text')

inputSearchSity?.addEventListener('input', (e) => {
  searchSity(e.target, sityList)
})

const inputSearchSityMobile = document.querySelector('.mobile__select-sity_input')
const sityListMobile = document.querySelectorAll('.mobile__sityes-group')

inputSearchSityMobile?.addEventListener('input', (e) => {
  searchSityMobile(e.target, sityListMobile)
})

function searchSity(input, list) {
  input.value = input.value.replace(/[^a-zA-Zа-яА-Я]/g, '');
  if (input.value == "") {
    list.forEach(group => {
      group.classList.remove('hide-group')
      const sityesInGroup = group.querySelectorAll('.sity-name')
      sityesInGroup.forEach(sity => {
        sity.classList.remove('hide')
      })
    });
    sityNotFound.classList.remove('show')
    setColumns()
    return
  }
  let isSearch = false;
  list.forEach(group => {
    const letter = group.querySelector('.letter__sityes')
    if (!(input.value.charAt(0).toLowerCase() == letter.textContent.toLowerCase())) {
      const sityesInGroup = group.querySelectorAll('.sity-name')
      sityesInGroup.forEach(sity => {
        sity.classList.add('hide')
      })
      group.classList.add('hide-group')
    } else {
      group.classList.remove('hide-group')
      const sityesInGroup = group.querySelectorAll('.sity-name')
      let isGroupSearch = false;
      sityesInGroup.forEach(sity => {
        if (!sity.textContent.toLowerCase().startsWith(input.value.toLowerCase())) {
          sity.classList.add('hide')
        }
        else {
          isSearch = true
          sity.classList.remove('hide')
          isGroupSearch = true;
        }
      })
      if (!isGroupSearch) {
        group.classList.add('hide-group')
      }
    }
  })
  setColumns()
  if (isSearch) {

  }
}


function searchSityMobile(input, list) {
  input.value = input.value.replace(/[^a-zA-Zа-яА-Я]/g, '');
  if (input.value == "") {
    list.forEach(group => {
      group.style.display = 'flex'
      const sityesInGroup = group.querySelectorAll('.mobile__sity-name')
      sityesInGroup.forEach(sity => {
        sity.style.display = 'block'
      })
    });
    return
  }
  list.forEach(group => {
    const letter = group.querySelector('.mobile__letter__sityes')
    if (!(input.value.charAt(0).toLowerCase() == letter.textContent.toLowerCase())) {
      group.style.display = 'none'
    } else {
      group.style.display = 'flex'
      const sityesInGroup = group.querySelectorAll('.mobile__sity-name')
      sityesInGroup.forEach(sity => {
        if (!sity.textContent.toLowerCase().startsWith(input.value.toLowerCase())) {
          sity.classList.add('hide')
        }
        else {
          sity.classList.remove('hide')
        }
      })
    }
  })
}

const swiperFlipper = new Swiper(".swiper__flipper", {
  slidesPerView: 1,
  spaceBetween: 20,
  pagination: {
    el: ".swiper__pagination-fraction",
    type: "fraction",
  },
})



const flipContainers = document.querySelectorAll('.flliper__slide');
flipContainers.forEach(slide => {
  const flipBtn = slide.querySelector('.btn-edit__flipper')
  const revertFlipBtn = slide.querySelector('.btn-flipper-save')
  flipBtn.addEventListener('click', () => {
    slide.classList.add('flipped');
  });
  revertFlipBtn.addEventListener('click', () => {
    slide.classList.remove('flipped');
  });
})

const navList = document.querySelector('.nav-list');
const navItems = navList.querySelectorAll('.nav-list__link');

// Функция для обработки пересечения (отображения элементов)
const callback = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const section = entry.target;

      // Найти соответствующий пункт меню и добавить ему класс active
      const activeItem = navList.querySelector('a[href*="#' + section.id + '"]');
      if (activeItem) {
        activeItem.classList.add('active');
        scrollToElementHorizontally(navList, activeItem);
      }

      // Удаляем класс active с других пунктов меню
      navItems.forEach(item => {
        if (item !== activeItem) {
          item.classList.remove('active');
        }
      });
    }
  });
};


function scrollToElementHorizontally(container, element) {
  // Определяем ширину контейнера и положение элемента
  const containerWidth = container.clientWidth;
  const elementLeft = element.offsetLeft;
  const elementWidth = element.offsetWidth;

  // Рассчитываем новое значение scrollLeft, чтобы элемент был по центру
  const scrollPosition = elementLeft - (containerWidth / 2) + (elementWidth / 2);

  // Прокручиваем контейнер к рассчитанному положению
  container.scrollTo({
    left: scrollPosition,
    behavior: 'smooth' // Плавная прокрутка
  });
}




// Создаем объект Intersection Observer с дополнительными опциями
const observerOptions = {
  root: null, // Обозначает, что область видимости — это сам viewport
  rootMargin: '0px',
  threshold: 0.1 // 10% секции должно быть видно для активации
};

const observer = new IntersectionObserver(callback, observerOptions);

// Получаем все секции и добавляем их в observer
document.querySelectorAll('section').forEach((section) => {
  observer.observe(section);
});

const products = document.querySelectorAll('.product-item')
const flliperClose = document.querySelector('.flipper__close')
flliperClose.addEventListener('click', () => {
  products.forEach(product => {
    product.classList.remove('selected')
    product.classList.remove('disabled')
  })
})
